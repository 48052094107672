/* You can add global styles to this file, and also import other style files */
// .form-select-white{
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
//     color: white !important;
//     option:not(:hover){
//         color: black !important;
//     }
// }

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

body{
    font-family: 'Titillium Web', sans-serif !important;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.pos-relative{
    position: relative;
}

.pos-absolute{
    position: absolute !important;
}

.cursor-pointer{
    cursor: pointer;
}

.analogue-1-bg-a1 .form-control{
    border: none !important;
}

.form-control.is-invalid:not(.form-select),
.autocomplete.is-invalid{
    background: none !important;
    padding-right: inherit !important;
    color: #cc334d !important;
    background-color: white !important;
    border-color: #cc334d !important;
}
textarea.is-invalid{
    border: 1px solid #cc334d !important;
}
.form-select.form-control.is-invalid{
    border: 1px solid #cc334d !important;
    background-color: white !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='hsl%28350deg, 60%, 50%%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
    color: #cc334d !important;
    
    option{
        color: black !important;
    }
}

.disabled{
    pointer-events: none;
    opacity: 0.3;
}

button{
    border-radius: 5px !important;
}

.text-left{
    text-align: start !important;
}

.accordion{
    
    .accordion-header .accordion-button{
        border-radius: 0 !important;
    }
    .accordion-header .accordion-button:hover{
        text-decoration: none !important;
        color: white;
        background-color:rgba(var(--bs-primary-rgb)) !important
    }
    .accordion-header .accordion-button.focus--mouse{
        border-color: #c5c7c9 !important;
    }
    .accordion-header .accordion-button:hover:after,
    .accordion-header .accordion-button:not(.collapsed):after{
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='hsl(210deg, 100%, 100%)' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E") !important;
    }
    .accordion-header .accordion-button:not(.collapsed){
        text-decoration: none !important;
        color: white;
        background-color: rgba(var(--bs-primary-rgb)) !important
    }

    .form-select{
        border: 1px solid #a3a3a3 !important;
        text-overflow: ellipsis;
        padding-right: 2.25rem !important;
    }
    
}

button.form-select{
    border-width: 1px !important;
    border-radius: 0 !important;
    text-align: start;
    div{
        text-overflow: ellipsis;
        white-space: nowrap !important;
        overflow: hidden;
    }
}
button.select-item{
    border: none !important;
    border-radius: 0 !important;
    text-align: start;
    background: none !important;
    padding: .75rem;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    font-size: 1rem;
}
button.select-item:hover, button.select-item:focus{
    background-color: rgba(var(--bs-primary-rgb)) !important;
    color: white !important;
}

button.form-select.form-select-header{
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 8 6 6 6-6'/%3e%3c/svg%3e") !important;
    color: white !important;
    border: none !important;
}

.dropdown-custom{
    position: absolute;
    top: 100%;
    z-index: 50;
    width: 100%;
    transition-duration: 0s !important;
}

.dropdown-container{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 25px rgba(77, 95, 109, 0.2) !important;
    background-color: white !important;
    width: fit-content !important;
    max-width: 100%;
    min-width: 100%;
    max-height: 15rem;
    overflow-y: auto;
}

.logo-header{
    height: 70%;
    max-height: 5rem;
}

.logo-generic{
    content: url('assets/images/generic_logo.svg');
}

.logo-footer{
    width: 100%;
    height: auto;
    // cursor: pointer;
}

.logo-camera{
    content: url('assets/images/logo_camera.svg');
    max-width: 265px;
}

.logo-unioncamere{
    content: url('assets/images/uc.png');
    max-width: 230px;
}

.logo-agid{
    content: url('assets/images/logo_agid.svg');
    max-width: 170px;
}

.logo_info_bianco{
    content: url('assets/images/Logo-negativo-_bianco_-alta-risoluzione_1.svg');
}
.logo-footer.logo_info_bianco{
    max-width: 147px;
}

@media (max-width: 992px) {
    .title-small{
        // display: block;
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .title-large{
        // display: none;

    }
    #sidebar{
        position: fixed;
        top: 0;
        z-index: 101;
        height: 100%;
        left: 0;
        min-width: 60vw;
        border: none !important;
        padding-top: 20vh !important;
    }
}
@media (min-width: 992px){
    .title-small{
        // display: none;
    }
    .title-large{
        // display: block;
    }
}

.btn-link-custom {
    padding: 0 !important;
    text-decoration: underline !important;
    margin: 0 !important;
    vertical-align: unset !important;
}

.btn-footer {
    color: white !important;
    font-weight: 600 !important;    
}
.btn-footer:hover {
    text-decoration: underline;
}